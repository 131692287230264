:root {
  /* rgb(250,250,250) */
  --main-body-color: #fafafa;
  /* rgb(244,244,244) */
  --text-color-light: #f4f4f4;
  /* rgb(168,168,168) */
  --text-color-regular: #a8a8a8;
  /* rgb(68,68,68) */
  --text-color-dark: #444444;
  /* rgb(250,234,222) */
  --main-theme-lightest: #faeade;
  /* rgb(243,206,177) */
  --main-theme-light: #f3ceb1;
  /* rgb(240,192,155) */
  --main-theme-regular: #f0c09b;
  /* rgb(233,164,111) */
  --main-theme-dark: #e9a46f;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  /* reject text selection */
  /* iOS Safari */
  -webkit-touch-callout: none;
  /* Safari */
  -webkit-user-select: none;
  /* Konqueror HTML */
  -khtml-user-select: none;
  /* Old versions of Firefox */
  -moz-user-select: none;
  /* Internet Explorer/Edge */
  -ms-user-select: none;
  /* chrome for android */
  -webkit-tap-highlight-color: transparent;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  user-select: none;
}

html, body, #root {
  height: 100%;
  width: 100%;
  /* height: -webkit-fill-available; */
}

body {
  background-color: var(--main-body-color);
}

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/* div {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  div::-webkit-scrollbar {
    display: none;
  } */